import { Component } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent {
  footerText = 'CGP - Coordenadoria de Gestão de Pessoas'
  footerTextSetor = 'CTINF - Coordenadoria de Tecnlogia da Informação'
  footerTextGrupoTecnico = 'GTDSI - Grupo Técnico de Desenvolvimento de Sistemas Institucionais'

  constructor() {}
}
