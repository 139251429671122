<div>
  &nbsp;
  <footer class="mt-1 text-center bg-light py-3">
    <div class="ngcontent-select-footer">
      <!--<a href="https://www2.unesp.br/portal#!/cgp" class="footer-link" target="_blank">-->
        Área responsável: {{ footerText }}
        <!--</a>-->
        <br />
        Proprietário:
        <a href="https://www2.unesp.br/portal#!/ctinf" class="footer-link" target="_blank">
          {{ footerTextSetor }}
        </a>
        | Desenvolvido por
        <a href="https://www2.unesp.br/portal#!/gtdsi" class="footer-link" target="_blank">
          {{ footerTextGrupoTecnico }}
        </a>
    </div>
  </footer>
</div>
